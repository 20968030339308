import React from 'react'

export const Spinner = () => {
  return (
    <svg width='18' height='19' viewBox='0 0 18 19' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M10.6875 1.9375C10.6875 2.88672 9.91406 3.625 9 3.625C8.05078 3.625 7.3125 2.88672 7.3125 1.9375C7.3125 1.02344 8.05078 0.25 9 0.25C9.91406 0.25 10.6875 1.02344 10.6875 1.9375ZM10.6875 16.5625C10.6875 17.5117 9.91406 18.25 9 18.25C8.05078 18.25 7.3125 17.5117 7.3125 16.5625C7.3125 15.6484 8.05078 14.875 9 14.875C9.91406 14.875 10.6875 15.6484 10.6875 16.5625ZM0 9.25C0 8.33594 0.738281 7.5625 1.6875 7.5625C2.60156 7.5625 3.375 8.33594 3.375 9.25C3.375 10.1992 2.60156 10.9375 1.6875 10.9375C0.738281 10.9375 0 10.1992 0 9.25ZM18 9.25C18 10.1992 17.2266 10.9375 16.3125 10.9375C15.3633 10.9375 14.625 10.1992 14.625 9.25C14.625 8.33594 15.3633 7.5625 16.3125 7.5625C17.2266 7.5625 18 8.33594 18 9.25ZM2.60156 15.6133C1.96875 14.9805 1.96875 13.8906 2.60156 13.2578C3.26953 12.5898 4.35938 12.5898 4.99219 13.2578C5.66016 13.8906 5.66016 14.9805 4.99219 15.6133C4.35938 16.2812 3.26953 16.2812 2.60156 15.6133ZM4.99219 5.27734C4.35938 5.94531 3.26953 5.94531 2.60156 5.27734C1.96875 4.64453 1.96875 3.55469 2.60156 2.88672C3.26953 2.25391 4.35938 2.25391 4.99219 2.88672C5.66016 3.55469 5.66016 4.64453 4.99219 5.27734ZM12.9727 13.2578C13.6055 12.5898 14.6953 12.5898 15.3633 13.2578C15.9961 13.8906 15.9961 14.9805 15.3633 15.6133C14.6953 16.2812 13.6055 16.2812 12.9727 15.6133C12.3047 14.9805 12.3047 13.8906 12.9727 13.2578Z'
        fill='white'
      />
    </svg>
  )
}
