import {SimpleObject} from '@/types'

export function isObject<T>(val: unknown): val is SimpleObject<T> {
  return (
    val !== null &&
    typeof val === 'object' &&
    Array.isArray(val) === false &&
    Object.prototype.toString.call(val) === '[object Object]'
  )
}
