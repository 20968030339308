'use client'

import {useEffect, useState} from 'react'
import styles from './styles.module.scss'
import {getClientSideCookie, setClientSideCookie} from '@/utils'
import UI_Typography from '@/components/ui-kit/typography/UI_Typography'
import Image from 'next/image'
import UI_Button from '@/components/ui-kit/button/Button'
import {isMobileDevice} from '@/app/_lib/csr-helpers'

export default function NewVersionModal() {
  const [isShow, setIsShow] = useState(false)
  const isMobile = isMobileDevice()

  const handleClose = () => {
    document.body.style.overflow = 'auto'
    setIsShow(false)
    setClientSideCookie('visitedV2', 'true')
  }

  useEffect(() => {
    const visitedV2 = getClientSideCookie('visitedV2')

    if (!visitedV2) {
      setIsShow(true)
      document.body.style.overflow = 'hidden'
    }
  }, [])

  if (!isShow) return null

  return (
    <div className={styles['new-version-modal']} data-testid='info-modal'>
      <div className={styles['new-version-modal__wrapper']}>
        <div className={styles['new-version-modal__wrapper__content']}>
          <Image
            src='/v2/Assets/Images/Logo.svg'
            alt='Tofisa'
            width={isMobile ? 150 : 200}
            height={isMobile ? 45 : 60}
            quality={100}
          />
          <UI_Typography
            className={styles['new-version-modal__wrapper__content__title']}
            variant='Medium/Med22'
            component={'h2'}
            color='#f26b26'
          >
            Yeni Tofisa.com’a Hoş Geldiniz!
          </UI_Typography>
          <UI_Typography
            variant={isMobile ? 'Regular/Reg16' : 'Regular/Reg18'}
            className={styles['new-version-modal__wrapper__content__description']}
          >
            Tofisa ailesi olarak, sizlere daha iyi bir deneyim sunabilmek için web sitemizi
            yeniledik!
          </UI_Typography>
          <UI_Typography
            variant='Regular/Reg24'
            className={styles['new-version-modal__wrapper__content__description']}
          >
            🥳
          </UI_Typography>
          <UI_Typography
            variant={isMobile ? 'Regular/Reg16' : 'Regular/Reg18'}
            className={styles['new-version-modal__wrapper__content__description']}
          >
            Hemen şimdi yeni hesabınızı oluşturun ve adres bilgilerinizi güncelleyin.
          </UI_Typography>
          <UI_Button onClick={handleClose} data-testid='info-modal-close-button'>
            Anladım
          </UI_Button>
        </div>
      </div>
    </div>
  )
}
