import {SimpleObject} from '@/types'
import {isObject} from '../type'
import {KeyboardEvent} from 'react'
import React, {Children} from 'react'

export const cleanObject = <T extends SimpleObject>(obj?: T): Partial<T> => {
  if (!isObject(obj)) return {}
  const newObj = {...obj}
  Object.keys(newObj).forEach((key) =>
    newObj[key] === undefined || newObj[key] === '' || newObj[key] === null
      ? delete newObj[key]
      : {}
  )
  return newObj
}

export const RenderIf = ({children, isTrue}: {children: any; isTrue: boolean}) => {
  return isTrue ? children : null
}

export const preventDefaultAndStopPropagation = (event: React.MouseEvent<HTMLElement>): void => {
  event.preventDefault()
  event.stopPropagation()
}

// use this function in onKeyDown inputs Prop
export const preventEnterNumberInputs = (e: KeyboardEvent<HTMLInputElement>) => {
  const isNumber = /^[0-9]/.test(e.key)
  if (isNumber) e.preventDefault()
}
export const Show = (props: any) => {
  let when: any = null
  let otherwise: any | null = null

  Children.forEach(props.children, (children: any) => {
    if (children.props.isTrue === undefined) {
      otherwise = children
    } else if (!when && children.props.isTrue === true) {
      when = children
    }
  })

  return when || otherwise
}

Show.When = ({isTrue, children}: any): any | null => isTrue && children
Show.Else = ({render, children}: any): any | null => render || children

export const expireDateForCookies = () => {
  // Set expiration date to 5 years from now
  const expirationDate = new Date()
  expirationDate.setFullYear(expirationDate.getFullYear() + 5)
  return expirationDate
}

export const setClientSideCookie = (name: string, value: string | number): void => {
  // Set expiration date to 5 years from now
  const expirationDate = expireDateForCookies()

  const cookieString = `${encodeURIComponent(name)}=${encodeURIComponent(
    value
  )}; expires=${expirationDate.toUTCString()}; path=/`
  document.cookie = cookieString
}

export const removeClientSideCookie = (name: string): void => {
  const expirationDate = new Date(0) // Set to epoch time (Thu, 01 Jan 1970 00:00:00 GMT)
  const cookieString = `${encodeURIComponent(
    name
  )}=; expires=${expirationDate.toUTCString()}; path=/`
  document.cookie = cookieString
}

export function getClientSideCookie(cookieName: string): string | undefined {
  if (typeof window !== 'undefined') {
    const matches = document?.cookie.match(new RegExp(`${cookieName}=([^;]+)`))
    return matches?.pop()
  }
}

export function formatPrice(price: number | string | undefined): string | null {
  if (!price) return null

  if (typeof price === 'string') {
    price = parseFloat(price)
  }

  const formattedPrice = new Intl.NumberFormat('tr-TR', {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(price)

  return formattedPrice
}

export function isNegative(value: string | number): boolean {
  if (typeof value === 'string') {
    return value.startsWith('-')
  } else {
    return Math.sign(value) === -1
  }
}

export const cleanTurkishChar = (str: string) => {
  return str.toLowerCase().replace('i', 'i').replace('ı', 'i').replace('i̇', 'i')
}

export const handleGetDefaultCountry = (): {
  id: number
  iso2: string
  languages: string[]
  primaryLanguage: string
} => {
  return JSON.parse(process.env.NEXT_PUBLIC_APP_DEFAULT_COUNTRY as string)
}

/**
 * Checks if the given date is today's date.
 * @param date - The date to check.
 * @returns `true` if the date is today's date, `false` otherwise.
 */
export const isToday = (date: Date): boolean => {
  const today = new Date()

  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  )
}
