'use client'

import {useReportWebVitals} from 'next/web-vitals'

export function WebVitals() {
  useReportWebVitals((metric) => {
    // eslint-disable-next-line
    console.debug(`${metric.name} web vital rating: ${metric.rating}`, metric)
  })

  return <></>
}
