import {useEffect, useState} from 'react'

function useCustomMediaQuery(threshold: number): boolean {
  const [isLessThanThreshold, setIsLessThanThreshold] = useState(
    typeof window !== 'undefined' ? window.innerWidth < threshold : false
  )

  useEffect(() => {
    const handleResize = () => {
      setIsLessThanThreshold(typeof window !== 'undefined' ? window.innerWidth < threshold : false)
    }

    handleResize() // Initial check when component mounts
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [threshold])

  return isLessThanThreshold
}

export default useCustomMediaQuery
