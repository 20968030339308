'use client'

import React, {ElementType, HTMLProps, ReactNode} from 'react'
import styles from './styles.module.scss'
import clsx from 'clsx'
import useCustomMediaQuery from '@/component-v2/core/IsLessThanMid'

export interface IUI_TypographyProps extends HTMLProps<HTMLElement> {
  variant?: keyof typeof variantClasses
  component?: ElementType
  className?: string
  children?: ReactNode
  color?: string
}

export const variantClasses = {
  'Regular/Reg53': styles['RegularReg53'],
  'Regular/Reg43': styles['RegularReg43'],
  'Regular/Reg34': styles['RegularReg34'],
  'Regular/Reg30': styles['RegularReg30'],
  'Regular/Reg28': styles['RegularReg28'],
  'Regular/Reg24': styles['RegularReg24'],
  'Regular/Reg22': styles['RegularReg22'],
  'Regular/Reg20': styles['RegularReg20'],
  'Regular/Reg18': styles['RegularReg18'],
  'Regular/Reg16': styles['RegularReg16'],
  'Regular/Reg14': styles['RegularReg14'],
  'Regular/Reg12': styles['RegularReg12'],
  'Regular/Reg10': styles['RegularReg10'],
  'Regular/Reg8': styles['RegularReg8'],
  'Medium/Med53': styles['MediumMed53'],
  'Medium/Med43': styles['MediumMed43'],
  'Medium/Med34': styles['MediumMed34'],
  'Medium/Med30': styles['MediumMed30'],
  'Medium/Med28': styles['MediumMed28'],
  'Medium/Med24': styles['MediumMed24'],
  'Medium/Med22': styles['MediumMed22'],
  'Medium/Med20': styles['MediumMed20'],
  'Medium/Med18': styles['MediumMed18'],
  'Medium/Med16': styles['MediumMed16'],
  'Medium/Med14': styles['MediumMed14'],
  'Medium/Med12': styles['MediumMed12'],
  'Medium/Med10': styles['MediumMed10'],
  'Medium/Med8': styles['MediumMed8'],
  'Body1-Rubik-Light-14': styles['Body1'],
  'Caption2-Rubik-Light-10': styles['Caption2'],
}

const UI_Typography = (props: IUI_TypographyProps) => {
  const isMobile = useCustomMediaQuery(500)
  const {
    variant = isMobile ? 'Regular/Reg12' : 'Regular/Reg18',
    component: Component = 'span',
    className,
    children,
    color,
    ...otherProps
  } = props
  const typographyStyle = {
    color: color,
  }
  return (
    <Component
      className={clsx(styles['base'], variantClasses[variant], className)}
      style={typographyStyle}
      {...otherProps}
    >
      {children}
    </Component>
  )
}

export default UI_Typography
